import React, { useMemo, useState } from "react";
import { useIntl, defineMessages, FormattedMessage } from "react-intl";
import Tabs from "theme/components/organisms/Tabs";
import Stack from "theme/components/atoms/Layout/Stack";
import { Cell } from "theme/components/templates/Grid";
import CheckExistingUserForm from "../CheckExistingUserForm/CheckExistingUserForm";
import LoginForm from "../LoginForm/LoginForm";
import { AlertError } from "../../../components/molecules/Alert";
import FlashMessages from "theme/modules/FlashMessages";

const messagesRegistered = defineMessages({
  title: {
    id: "components.organisms.Login.LoginForm.title",
    defaultMessage: "Already registered?",
  },
});

const messagesNew = defineMessages({
  title: {
    id: "components.organisms.Login.CheckExistingUserForm.title",
    defaultMessage: "New Here?",
  },
  joinKlub: {
    id: "components.organisms.Register.RegisterForm.klub",
    defaultMessage: "Create your account and join Le Klub.",
  },
});

const ChooseAccountSelectorBase = ({
  onLoginSuccess,
  actionOnRegister,
  fbButtonAppearrance,
  titleLogin,
  titleCheckExistingUser,
  subtitleCheckExistingUser,
}) => {
  const [currentData, setCurrentData] = useState({
    email: "",
  });
  const [loginError, setLoginError] = useState(null);
  const intl = useIntl();
  const tabs = useMemo(
    () => [
      {
        key: "hasAccount",
        anchor: "hasAccount",
        label: intl.formatMessage(messagesRegistered.title),
        children: (
          <div className="tabcontent">
            <Cell size="desktop-1">
              {loginError && <AlertError>{loginError}</AlertError>}
              <FlashMessages />
              <Stack size="4">
                {titleLogin}
                <LoginForm
                  onLoginSuccess={onLoginSuccess}
                  onChange={(data) => setCurrentData(data)}
                  value={{ email: currentData.email }}
                  setLoginError={setLoginError}
                  enableFacebook
                  fbButtonAppearrance={fbButtonAppearrance}
                />
              </Stack>
            </Cell>
          </div>
        ),
      },
      {
        key: "newAccount",
        anchor: "newAccount",
        label: intl.formatMessage(messagesNew.title),
        children: (
          <div className="tabcontent">
            <Cell size="desktop-1">
              <Stack size="4">
                {titleCheckExistingUser}
                {subtitleCheckExistingUser}
                <CheckExistingUserForm
                  {...(actionOnRegister && { actionOnRegister })}
                  onLoginSuccess={onLoginSuccess}
                />
              </Stack>
            </Cell>
          </div>
        ),
      },
    ],
    [loginError, intl, actionOnRegister, currentData, onLoginSuccess]
  );

  return (
    <Tabs
      tabs={tabs}
      scrollOnTabSwitch={false}
      setScrollTo={false}
      isScrollingTo={false}
    />
  );
};

export default ChooseAccountSelectorBase;
