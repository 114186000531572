import React from "react";
import {
  InfoAlert,
  ErrorAlert,
  SuccessAlert,
} from "theme/components/molecules/Alert";
import { BodyParagraph } from "theme/components/atoms/Typography/Body";
import PaylinePaymentError from "theme/modules/Payline/FlashMessage/PaylinePaymentError";
import {
  AdyenPaymentSuccess,
  AdyenPaymentError,
} from "theme/modules/Adyen/FlashMessage";

const makeAlertMessageComponent =
  (AlertComponent) =>
  ({ children }) =>
    (
      <AlertComponent>
        <BodyParagraph>{children}</BodyParagraph>
      </AlertComponent>
    );

const ComponentMap = {
  default: makeAlertMessageComponent(InfoAlert),
  info: makeAlertMessageComponent(InfoAlert),
  error: makeAlertMessageComponent(ErrorAlert),
  success: makeAlertMessageComponent(SuccessAlert),
  paylineError: PaylinePaymentError,
  paylineSuccessOrPending:() => null,
  adyenSuccess: AdyenPaymentSuccess,
  adyenError: AdyenPaymentError,
};

const getFlashMessageComponent = (type = "default") => {
  return ComponentMap[type] || ComponentMap.default;
};

export default getFlashMessageComponent;
