import React from "react";
import { AlertError } from "theme/components/molecules/Alert";
import { BodyParagraph } from "theme/components/atoms/Typography/Body";
import { defineMessages, useIntl } from "react-intl";
import PaylinePaymentErrorQuery from "./PaylinePaymentErrorQuery.gql";
import EnhancePaylinePaymentError from "./EnhancePaylinePaymentError";

// see https://docs.payline.com/display/DT/Return+codes
const messages = defineMessages({
  mismatch: {
    id: "modules.Payline.FlashMessage.PaylinePaymentError.mismatch",
    defaultMessage:
      "The transaction amount did not match the order total. The payment was successful but your order is on hold. If you think there is a problem, please contact us.",
  },
  cancelledByUser: {
    id: "modules.Payline.FlashMessage.PaylinePaymentError.cancelledByUser",
    defaultMessage:
      "You cancelled the payment. If you need help, please contact us.",
  },
  resignation: {
    id: "modules.Payline.FlashMessage.PaylinePaymentError.resignation",
    defaultMessage:
      "Your transaction or your payment session expired. Please try again.",
  },
  default: {
    id: "modules.Payline.FlashMessage.PaylinePaymentError.default",
    defaultMessage:
      "The payment was not successful. If you think it is a technical problem, please contact us. (Code: {resultCode})",
  },
  unknownCode: {
    id: "modules.Payline.FlashMessage.PaylinePaymentError.unknownCode",
    defaultMessage: "N/A",
  },
});

const isMismatch = (code) => code === "00000";
const isResignation = (code) => ["02304", "02324", "02534"].includes(code);
const isUserCancellation = (code) => ["02008", "02319"].includes(code);

const PaylinePaymentError = ({ data, loading, paylineErrorMessages }) => {
  const intl = useIntl();
  const resultCode = data.resultCode;

  let messageDescriptor = messages.default;
  if (isMismatch(resultCode)) {
    messageDescriptor = messages.mismatch;
  } else if (isResignation(resultCode)) {
    messageDescriptor = messages.resignation;
  } else if (isUserCancellation(resultCode)) {
    messageDescriptor = messages.cancelledByUser;
  }

  const paylineMessage =
    paylineErrorMessages &&
    paylineErrorMessages.find((message) => message.error_code === resultCode);

  if (loading) {
    return null;
  }

  return (
    <AlertError fullWidth>
      <BodyParagraph>
        {paylineMessage
          ? paylineMessage.custom
          : intl.formatMessage(messageDescriptor, {
              resultCode:
                resultCode ?? intl.formatMessage(messages.unknownCode),
              message: data.message,
            })}
      </BodyParagraph>
    </AlertError>
  );
};

export default EnhancePaylinePaymentError({ PaylinePaymentErrorQuery })(
  PaylinePaymentError
);
