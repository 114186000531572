import { compose } from "recompose";
import { graphql } from "react-apollo";

const EnhancePaylinePaymentError = ({ PaylinePaymentErrorQuery }) =>
  compose(
    graphql(PaylinePaymentErrorQuery, {
      props: ({ data }) => ({
        loading: data.loading,
        paylineErrorMessages:
          !data.loading &&
          !data.error &&
          (data?.storeConfiguration?.paylineErrorMessages ?? false),
      }),
    })
  );

export default EnhancePaylinePaymentError;
