import React from "react";
import EnhanceFlashMessages from "./EnhanceFlashMessages";
import getFlashMessageComponent from "./getFlashMessageComponent";
import Stack from "theme/components/atoms/Layout/Stack";

const FlashMessageItem = ({ flashMessage }) => {
  if (typeof flashMessage === "string") {
    const Component = getFlashMessageComponent();
    return <Component>{flashMessage}</Component>;
  }

  if (typeof flashMessage === "object") {
    const { message, type, data = {} } = flashMessage;
    const Component = getFlashMessageComponent(type);
    return <Component data={data}>{message}</Component>;
  }

  return null;
};

export const FlashMessages = ({ flashMessages }) => {
  if (!flashMessages?.length) {
    return null;
  }

  return (
    <Stack>
      {flashMessages.map((flashMessage, index) => (
        <FlashMessageItem key={index} flashMessage={flashMessage} />
      ))}
    </Stack>
  );
};

export default EnhanceFlashMessages()(FlashMessages);
